import { agentApi } from './base';
import { PersonalizationSettings } from './types';

export const personalizationApi = agentApi.injectEndpoints({
	endpoints: (builder) => ({
		// GET /personalization
		getPersonalizationSettings: builder.query<PersonalizationSettings, void>({
			query: () => ({
				url: '/personalization/',
				method: 'GET',
			}),
		}),

		// POST /personalization/update
		updatePersonalizationSettings: builder.mutation<
			{ status: string },
			Partial<PersonalizationSettings>
		>({
			query: (body) => ({
				url: '/personalization',
				method: 'POST',
				body,
			}),
		}),
	}),
});

export const {
	useGetPersonalizationSettingsQuery,
	useUpdatePersonalizationSettingsMutation,
} = personalizationApi;
