import { parseISO } from "date-fns";

/**
 * Extracts and formats error messages from a validation error object.
 * @param {object} validationError The validation error object
 * @return {string} Formatted error string
 */
export function getErrorString(validationError: any) {
	if (!validationError || !validationError.body_params) {
		return "";
	}
	const { body_params } = validationError;
	return body_params
		.map((param: any) => {
			const location = param.loc.join(" -> ");
			return `Error in ${location}: ${param.msg}`;
		})
		.join("\n");
}

/**
 * Retrieves the value of a cookie by its name.
 * @param {string} name The name of the cookie
 * @return {string|undefined} The cookie value if found, undefined otherwise
 */
export function getCookie(name: string) {
	const value = "; " + document.cookie;
	const parts = value.split("; " + name + "=");
	if (parts.length == 2) return parts.pop()?.split(";").shift();
}

/**
 * Cleans HTML agenda by removing tags and formatting the text.
 * @param {string} htmlAgenda The HTML agenda string
 * @return {string} Cleaned agenda text
 */
export function cleanAgenda(htmlAgenda: string) {
	// Replace HTML tags with appropriate newlines and spaces
	const cleanedAgenda = htmlAgenda
		.replace(/<br\s*\/?>/gi, "\n")
		.replace(/<ol>/gi, "\n")
		.replace(/<\/ol>/gi, "")
		.replace(/<li>/gi, "\n- ")
		.replace(/<\/li>/gi, "")
		.replace(/\\u00a0/gi, " ")
		.replace(/^\s+|\s+$/g, "")
		.replace(/\n{2,}/g, "\n\n");

	return cleanedAgenda.trim();
}

/**
 * Validates an email address.
 * @param {string} email The email address to validate
 * @return {boolean} True if the email is valid, false otherwise
 */
export const isValidEmail = (email: string): boolean => {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return emailRegex.test(email);
};

/**
 * Validates a domain name.
 * @param {string} domain The domain name to validate
 * @return {boolean} True if the domain is valid, false otherwise
 */
export const isValidDomain = (domain: string): boolean => {
	const domainRegex =
		/^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
	return domainRegex.test(domain);
};

/**
 * Converts a snake_case string to camelCase.
 * @param {string} str The snake_case string to convert
 * @return {string} The converted camelCase string
 */
export const snakeToCamel = (str: string) =>
	str
		.toLowerCase()
		.replace(/([-_][a-z])/g, (group) =>
			group.toUpperCase().replace("-", "").replace("_", ""),
		);

//@ts-ignore
/**
 * Checks if the current environment is a Chrome extension.
 * @return {boolean} True if running as a Chrome extension, false otherwise
 */
//@ts-ignore
export const isChromeExtension = Boolean(window?.chrome?.runtime?.id);

/**
 * Checks if the current environment is local.
 * @return {boolean} True if the environment is local, false otherwise
 */
export const isLocal = () =>
	process.env.MIGHTYBOT_API_URI_ENV == "local" || process.env.ENV == "local";

/**
 * Formats duration from either start/end dates or seconds into hours and minutes.
 * @param {string | number} start The start date string or duration in seconds
 * @param {string} [end] Optional end date string
 * @param {boolean} [onlyMinutes] If true, returns only the minutes
 * @return {string | number} Formatted duration string (e.g., "2h 30m") or minutes if onlyMinutes is true
 */
export const formatDuration = (
	start: string | number,
	end?: string,
	onlyMinutes: boolean = false,
) => {
	let duration: number;

	if (typeof start === "number") {
		// Handle seconds input
		duration = start * 1000; // Convert seconds to milliseconds
	} else if (end) {
		// Handle start/end date strings
		duration = new Date(end).getTime() - new Date(start).getTime();
	} else {
		return onlyMinutes ? 0 : "0h 0m";
	}

	const hours = Math.floor(duration / (1000 * 60 * 60));
	const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));

	if (onlyMinutes) {
		return minutes;
	}

	if (hours === 0) {
		return `${minutes}m`;
	}

	return `${hours}h ${minutes}m`;
};

/**
 * Formats a date string into a readable date and time format.
 * @param {string} dateString The date string to format
 * @return {string} Formatted date and time string
 */
export const formatDateTime = (dateString: string) => {
	const date = new Date(dateString);
	return `${date.toLocaleString("en-US", { month: "short" })} ${date.getDate()}, ${date.getFullYear()}, ${date.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })}`;
};

/**
 * Formats a date string into a readable format
 * @param dateString - The date string to format
 * @returns The formatted date string in the format of "Month Day, Year" example: "December 31, 2024"
 */
export const formatDate = (dateString: string) => {
	if (!dateString) return "";
	const date = new Date(dateString);
	return date.toLocaleDateString("en-US", {
		month: "short",
		day: "numeric",
		year: "numeric",
	});
};

/**
 * Formats a date-time string into separate day-date and time strings.
 * @param {string} dateTimeString The date-time string to format
 * @return {Object} An object containing formatted dayDate and time strings
 */
export const formatMeetingDateTime = (
	dateTimeString: string,
): { dayDate: string; time: string } => {
	const date = new Date(dateTimeString);

	const dayDateOptions: Intl.DateTimeFormatOptions = {
		weekday: "long",
		month: "short",
		day: "numeric",
	};

	const timeOptions: Intl.DateTimeFormatOptions = {
		hour: "numeric",
		minute: "2-digit",
		hour12: true,
	};

	return {
		dayDate: date.toLocaleString("en-US", dayDateOptions),
		time: date.toLocaleString("en-US", timeOptions),
	};
};

/**
 * Formats a given date string into a human-readable timestamp.
 * @param {string} created_at The date string to format
 * @return {string} A formatted timestamp string
 *
 * The function returns:
 * - Time (e.g., "2:30 PM") if the date is today
 * - "Yesterday" if the date was yesterday
 * - Number of days ago (e.g., "3 days ago") if within the last week
 * - Date (e.g., "Mar 15, 2023") if older than a week
 */
export const formatTimestamp = (created_at: string) => {
	const createdAt = new Date(created_at);
	const now = new Date();
	const diffInHours = (now.getTime() - createdAt.getTime()) / (1000 * 60 * 60);

	let timestamp: string;
	if (diffInHours < 24 && createdAt.getDate() === now.getDate()) {
		timestamp = createdAt.toLocaleTimeString([], {
			hour: "2-digit",
			minute: "2-digit",
			hour12: true,
		});
	} else if (diffInHours < 48) {
		timestamp = "Yesterday";
	} else if (diffInHours < 168) {
		timestamp = `${Math.floor(diffInHours / 24)} days ago`;
	} else {
		timestamp = createdAt.toLocaleDateString([], {
			year: "numeric",
			month: "short",
			day: "numeric",
		});
	}
	return timestamp;
};

/**
 * Formats a markdown string by removing extra newlines and spaces.
 * @param {string} markdown The markdown string to format
 * @return {string} The formatted markdown string
 */
export const formatMarkdown = (markdown: string) => {
	return (
		markdown
			// First convert headers that aren't h5 to normal text
			// (negative lookahead to ignore ##### headers)
			.replace(/^(?!##### )#{1,6} /gm, "")
			// Replace multiple newlines with single newline
			.replace(/\n\s*\n/g, "\n")
			// Remove newlines that aren't after headers or list items
			.replace(/(?<!^|\n#.*|\n-\s.*|\n\d+\.\s.*)\n/g, " ")
			// Clean up extra spaces
			.replace(/\s+/g, " ")
			.trim()
	);
};

/**
 * Checks if the current device is a mobile device.
 * This function uses a combination of screen width and user agent string to determine if the device is mobile.
 * @return {boolean} True if the device is considered mobile, false otherwise
 */
export const isMobile = (): boolean => {
	const mobileWidth = 768; // You can adjust this threshold as needed
	const userAgent =
		navigator.userAgent || navigator.vendor || (window as any).opera;

	const mobileRegex =
		/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i;

	return window.innerWidth <= mobileWidth || mobileRegex.test(userAgent);
};

/**
 * Capitalizes each word in a string.
 * @param {string} str The string to be capitalized
 * @returns {string} The string with the first letter of each word capitalized
 * @example
 * capitalizeString('hello world') // returns 'Hello World'
 * capitalizeString('my-first-name') // returns 'My-First-Name'
 * capitalizeString('') // returns ''
 */
export const capitalize = (str: string): string => {
	if (!str) return "";
	return str
		.split(/([^a-zA-Z0-9])/g) // Split on non-alphanumeric characters while keeping the separators
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join("");
};

export const camelize = (str: string, splitOn?: string): string => {
	if (!str) return "";
	return str
		.split(splitOn ?? "_")
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(" ");
};

/**
 * Checks if a Google signed URL is expired or about to expire in the next 5 minutes.
 * @param {string} url The Google signed URL to check
 * @return {boolean} True if the URL is expired or about to expire, false otherwise
 */
export const isGoogleSignedUrlExpired = (url: string): boolean => {
	try {
		const urlObj = new URL(url);
		const expiresParam = urlObj.searchParams.get("X-Goog-Expires");
		const dateParam = urlObj.searchParams.get("X-Goog-Date");

		if (!expiresParam || !dateParam) {
			return true;
		}

		const signedTime = parseISO(dateParam).getTime();
		const expirationDuration = parseInt(expiresParam) * 1000;
		const expirationTime = signedTime + expirationDuration;
		const currentTime = Date.now();
		const threshold = 1 * 60 * 1000;

		const timeLeft = expirationTime - currentTime;

		return timeLeft < threshold;
	} catch (error) {
		console.error("Error checking Google signed URL expiration:", error);
		return true;
	}
};

/**
 * Formats a time range into a human-readable string.
 * @param {string} startTime The start time string
 * @param {string} endTime The end time string
 * @return {string} A formatted time range string
 */
export const formatTimeRange = (startTime: string, endTime: string) => {
	const start = new Date(startTime);
	const end = new Date(endTime);

	const formatTime = (date: Date) => {
		let hours = date.getHours();
		const minutes = date.getMinutes();
		const ampm = hours >= 12 ? "pm" : "am";

		hours = hours % 12;
		hours = hours ? hours : 12; // Convert 0 to 12

		const minutesStr =
			minutes === 0 ? "" : ":" + minutes.toString().padStart(2, "0");
		return `${hours}${minutesStr}`;
	};

	const startFormatted = formatTime(start);
	const endFormatted = formatTime(end);
	const endAmPm = end.getHours() >= 12 ? "pm" : "am";

	return `${startFormatted} – ${endFormatted}${endAmPm}`;
};

/**
 * Gets the offset of the cursor in an input element.
 * @param {HTMLInputElement} input The input element
 * @param {number} position The position of the cursor
 * @return {Object} An object containing the left and top coordinates of the cursor
 */
export const getCursorOffset = (input: HTMLInputElement, position: number) => {
	const dummy = document.createElement("span");
	const style = window.getComputedStyle(input);

	// Copy styles to dummy element
	dummy.style.font = style.font;
	dummy.style.padding = style.padding;
	dummy.style.border = style.border;
	dummy.style.position = "absolute";
	dummy.style.visibility = "hidden";

	// Get text before cursor
	dummy.textContent = input.value.substring(0, position);

	document.body.appendChild(dummy);
	const offset = { left: dummy.offsetWidth, top: dummy.offsetHeight };
	document.body.removeChild(dummy);

	return offset;
};

/**
 * Scrolls a container to bring an element into view, accounting for fixed headers.
 * @param {HTMLElement} container The scrollable container element
 * @param {HTMLElement} element The target element to scroll to
 * @param {number} offset Additional offset from the top (default: 100px)
 */
export const scrollToElement = (
	container: HTMLElement,
	element: HTMLElement,
	offset: number = 100,
) => {
	const containerRect = container.getBoundingClientRect();
	const elementRect = element.getBoundingClientRect();
	const titleHeight =
		document.querySelector("[data-title]")?.getBoundingClientRect().height || 0;
	const tabsHeight =
		document.querySelector(".h-\\[61px\\]")?.getBoundingClientRect().height ||
		0;

	// Calculate the total offset including fixed elements
	const totalOffset = titleHeight + tabsHeight + offset;

	// Calculate the new scroll position
	const scrollPosition =
		elementRect.top - containerRect.top + container.scrollTop - totalOffset;

	container.scrollTo({
		top: Math.max(0, scrollPosition), // Prevent negative scroll
		behavior: "smooth",
	});
};

/**
 * Formats an amount string into a human-readable label.
 * @param {string} amount The amount string to format
 * @return {string} The formatted amount label
 */
export const formatAmountLabel = (amount: string) => {
	// Convert "0-10k" to "Up to 10K"
	// Convert "10k-50k" to "10K to 50K"
	// Convert "1M+" to "Over 1M"
	if (amount.endsWith("+")) {
		return `Over ${amount.slice(0, -1)}`;
	}

	const [min, max] = amount.split("-");
	if (min === "0") {
		return `Up to ${max.toUpperCase()}`;
	}

	return `${min.toUpperCase()} to ${max.toUpperCase()}`;
};

export const getDomainFromUrl = (url: string) => {
	if (!url) {
		return "";
	}
	try {
		return new URL(url).hostname;
	} catch (error) {
		return url; 
	}
};