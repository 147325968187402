import React from "react";
import { Dialog, Flex, Text, Button, Icon, TextField, TextArea } from "@mightybot/web-ui";

type DialogProps = {
    showModal: boolean;
    handleModalClose: () => void;
    title: string;
    description: string;
};

const SendNotificationModal = ({
    showModal,
    handleModalClose,
    title,
    description,
}: DialogProps) => {
    return (

        <Dialog.Root open={showModal} onOpenChange={handleModalClose}>
            <Dialog.Content maxWidth="550px" aria-describedby="send notification for errors">
                <Flex justify="between">
                    <Dialog.Title >
                        {title}
                    </Dialog.Title>
                    <Icon.X onClick={handleModalClose} style={{ cursor: "pointer" }} />
                </Flex>
                <hr />

                <Flex direction="column" gap="3">
                    <label>
                        <Text as="div" size="3" mb="1" weight="bold">
                            Borrower Name
                        </Text>
                        <TextField.Root
                            defaultValue="John Doe"
                            placeholder="Enter your full name"
                            aria-describedby="send notification for errors"
                        />
                    </label>
                    <label>
                        <Text as="div" size="3" mb="1" weight="bold">
                            Description
                        </Text>
                        <TextArea
                            rows={4}
                            defaultValue={description}
                            placeholder="Enter your description"
                            aria-describedby="send notification for errors"
                        />
                    </label>
                </Flex>

                <Flex gap="3" mt="4" justify="end">
                    <Dialog.Close>
                        <Button variant="soft" color="gray">
                            Cancel
                        </Button>
                    </Dialog.Close>
                    <Dialog.Close>
                        <Button>Send Notification</Button>
                    </Dialog.Close>
                </Flex>
            </Dialog.Content>
        </Dialog.Root>
    );
};

export default SendNotificationModal;
