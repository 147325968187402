import React from "react";
import { RuleStatus, SectionId, Status, StepId } from "@mightybot/core/api/drawApi";
import { Icon, Spinner } from "@mightybot/web-ui";
import { Badge, Flex, IconProps, Text } from "@mightybot/web-ui";

export const getSectionTitle = (id: SectionId, type: "text" | "icon" = "text"): React.ReactNode => {
    switch (id.toUpperCase()) {
        case "DOCUMENT_VERIFICATION": return type === "text" ? "Verifying Uploads" : (<Flex align="center" gap="2">
            <Icon.FileArrowUp size={24} color="var(--gray-8)" />
            Verifying Uploads</Flex>);
        case "DRAW_RECONCILIATION": return type === "text" ? "Draw Reconciliation" : (<Flex align="center" gap="2">
            <Icon.Signature size={24} color="var(--gray-8)" />
            Draw Reconciliation</Flex>);
        case "DRAW_CHECKLIST": return type === "text" ? "Draw Checklist" : (<Flex align="center" gap="2">
            <Icon.CheckFat size={24} color="var(--gray-8)" />
            Draw Checklist</Flex>);
        default: return type === "text" ? id.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()) : (<>
            <Icon.FileArrowUp size={24} color="var(--gray-8)" />
            {id.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</>);
    }
};

export const getStepTitle = (id: StepId): string => {
    switch (id.toUpperCase()) {
        case "DOCUMENT_VALIDATION": return "Analyzing Uploaded Files";
        case "DATA_EXTRACTION": return "Data Extraction";
        case "DOCUMENT_CONTENT_VALIDATION": return "Document Content Validation";
        case "DRAW_RECONCILIATION": return "Draw Budget Alignment";
        case "IDENTIFYING_DISCREPANCIES": return "Identifying Discrepancies in Draw Line Items";
        case "CHECKLIST_VALIDATION": return "Checklist Validation Results";
        default: return id.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
    }
};


export const getStatusBadgeColor = (status: Status | RuleStatus): React.ComponentProps<typeof Badge>['color'] => {
    switch (status?.toUpperCase()) {
        case "COMPLETED":
        case "VALID":
        case "PASSED":
        case "RESOLVED":
            return "green";
        case "WARNING":
        case "MEDIUM":
            return "yellow";
        case "FAILED":
        case "INVALID":
        case "HIGH":
        case "OPEN":
            return "red";
        case "PENDING":
        case "IN_PROGRESS":
            return "blue";
        case "NOT_APPLICABLE":
        default:
            return "gray";
    }
};

export const getStatusIcon = (status: Status | RuleStatus): React.ReactElement<IconProps> | null => {
    const size = 24;
    const color = getStatusBadgeColor(status);

    let iconColor: string;
    switch (color) {
        case 'green': iconColor = 'var(--green-9)'; break;
        case 'yellow': iconColor = 'var(--yellow-6)'; break;
        case 'red': iconColor = 'var(--red-9)'; break;
        case 'blue': iconColor = 'var(--blue-9)'; break;
        case 'gray': default: iconColor = 'var(--gray-8)'; break;
    }

    switch (status?.toUpperCase()) {
        case "COMPLETED":
        case "VALID":
        case "PASSED":
        case "RESOLVED":
            return <Icon.CheckCircle size={size} weight="fill" color={iconColor} />;
        case "WARNING":
        case "MEDIUM":
            return <Icon.Warning size={size} weight="fill" color={iconColor} />;
        case "FAILED":
        case "INVALID":
        case "HIGH":
        case "OPEN":
            return <Icon.XCircle size={size} weight="fill" color={iconColor} />;
        case "PENDING":
            return <Icon.ClockClockwise size={size} weight="bold" color={iconColor} />; // Use bold for pending
        case "IN_PROGRESS":
            return <Spinner size={size} weight="bold" color={iconColor} />;
        case "NOT_APPLICABLE":
            return <Icon.Prohibit size={size} weight="fill" color={iconColor} />;
        default:
            return <Icon.Info size={size} weight="fill" color={iconColor} />; // Default icon
    }
};

export const getRuleStatusIcon = (status: RuleStatus): React.ReactElement<IconProps> => {
    const size = 16;
    switch (status.toUpperCase()) {
        case "PASSED":
            return <Icon.Check size={size} color="var(--green-9)" weight="bold" />;
        case "FAILED":
            return <Icon.XCircle size={size} color="var(--red-9)" weight="fill" />;
        case "PENDING":
            return <Icon.Warning size={size} color="var(--yellow-9)" weight="bold" />;
        case "NOT_APPLICABLE":
            return <Icon.Minus size={size} color="var(--gray-8)" weight="bold" />;
        default:
            return <Icon.Info size={size} color="var(--gray-8)" weight="fill" />;
    }
}

export const formatDate = (dateString: string | null | undefined): string => {
    if (!dateString) return "N/A";
    try {
        return new Date(dateString).toLocaleString();
    } catch (e) {
        return dateString;
    }
};

export const renderSummary = (text: string | null | undefined) => {
    if (!text) return null;
    return <Text as="p" size="2" color="gray" > {text} </Text>;
};

export const renderKeyInsights = (insights: string[] | undefined) => {
    if (!insights || insights.length === 0) return null;
    return (
        <Flex direction="column" >
            {
                insights.map((insight, index) => (
                    <Text as="span" size="2" color="gray" > {insight} </Text>
                ))
            }
        </Flex>
    );
};

export const formatElapsedTime = (totalSeconds: number): string => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    if (hours > 0) {
        return `${hours}h ${minutes}m ${seconds}s`;
    } else if (minutes > 0) {
        return `${minutes}m ${seconds}s`;
    } else {
        return `${seconds}s`;
    }
};
