import React from "react";
import GetBuilt from "../components/GetBuilt";

const GetBuiltRoute: React.FC = () => {
    return (
        // <Layout>
        <GetBuilt />
        // </Layout>
    );
};

export default GetBuiltRoute;
