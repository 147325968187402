import React, { useState, useRef, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SerializedError } from '@reduxjs/toolkit';
import { Badge, Box, Card, Heading, Flex, Icon, Separator, Spinner, Text, IconButton, Grid, Button } from '@mightybot/web-ui';
import { useDraw } from '@mightybot/core/hooks/useDraw';
import { DrawData, Step, Section, Issue, Rule, StepId, UseDrawResult, Status } from '@mightybot/core/api/drawApi';

import {
    getSectionTitle,
    getStepTitle,
    getStatusBadgeColor,
    getStatusIcon,
    formatDate,
    renderSummary,
    renderKeyInsights,
    getRuleStatusIcon,
    formatElapsedTime,
} from './helpers';
import { BuiltHeader, MenuContainer, HoverIcons, StepContainer, StepContent } from './styled';
import SendNotificationModal from './SendNotificationModal';
import { camelize } from '../../utils/helpers';

const StepRenderer: React.FC<{ step: Step; allIssues: Issue[]; setShowModal: (showModal: boolean) => void, showModal: boolean, onSendNotification?: () => void }> = ({ step, allIssues, setShowModal, showModal, onSendNotification }) => {
    const stepTitle = getStepTitle(step.step_id);
    const stepIcon = getStatusIcon(step.status);
    const [showSummary, setShowSummary] = useState(step.step_id.toUpperCase() === "CHECKLIST_VALIDATION");
    // const stepIssues = allIssues.filter(issue => issue.step_id === step.step_id);

    const handleSendNotification = () => {
        onSendNotification?.();
        setShowModal(false);
    }

    const toggleSummary = (e: React.MouseEvent) => {
        e.stopPropagation();
        setShowSummary(!showSummary);
    };

    const renderStepContent = (showContent: boolean) => {
        switch (step.step_id?.toUpperCase()) {
            case "DOCUMENT_VALIDATION":
                return (
                    <>
                        <StepContent showSummary={showContent}>
                            {renderSummary(step.output?.summary || (step.metadata?.documents_found !== undefined ?
                                (step.metadata.documents_found === step.metadata.total_documents &&
                                    step.metadata.documents_missing === 0 &&
                                    step.metadata.duplicates_found === 0)
                                    ? `All ${step.metadata.documents_found} required documents found, with no duplicates or missing documents detected`
                                    : `Found: ${step.metadata.documents_found}, Missing: ${step.metadata.documents_missing ?? 0}, Duplicates: ${step.metadata.duplicates_found ?? 0}, Total: ${step.metadata.total_documents}`
                                : undefined
                            ))}
                        </StepContent>
                        {step.documents && step.documents.length > 0 && (
                            <Flex wrap="wrap" gap="2" mt="2">
                                {step.documents.map((doc, idx) => (
                                    <Badge key={idx} color={getStatusBadgeColor(doc.status)} variant="soft" size="3">
                                        <Icon.File size={12} weight="bold" /> <Text ml="1" size="2">{doc.file_name}</Text>
                                    </Badge>
                                ))}
                            </Flex>
                        )}
                        {step.error && (
                            <Box m="3" mt="5" maxWidth="600px">
                                <Card style={{
                                    borderRadius: "0.32238rem",
                                    borderColor: "var(--yellow-6)",
                                    borderWidth: "1px",
                                    borderStyle: "solid",
                                    backgroundColor: "var(--yellow-2)"
                                }}
                                    variant="ghost">
                                    <Flex direction="column" >
                                        <Text size="1">We found some issues with the uploaded documents. Please review the documents and upload the corrected version.</Text>
                                        <Button variant="solid" size="2" onClick={handleSendNotification} style={{ marginTop: "12px", width: "300px" }}>
                                            <Icon.PaperPlaneTilt />
                                            <Text>Send Borrower for Correction</Text>
                                        </Button>
                                    </Flex>
                                </Card>
                            </Box>
                        )}
                    </>
                );
            case "DATA_EXTRACTION":
                return (
                    <StepContent showSummary={showContent}>
                        {renderSummary(`The data extraction process successfully handled ${step.metadata?.documents_processed} documents,
                        ensuring that all required information was retrieved without any missing files.
                        This indicates that the system efficiently processed and extracted relevant data from all submitted documents.`)}
                    </StepContent>
                );
            case "DOCUMENT_CONTENT_VALIDATION":
                return (
                    <StepContent showSummary={showContent}>
                        {renderSummary(`For document content validation, all ${step.metadata?.successful_documents} documents passed successfully, with ${step.metadata?.failed_documents} failures.
                            This confirms that the extracted data met the required validation criteria, ensuring accuracy and completeness. The absence of failed documents suggests that the submission is well-structured and free of content-related errors, reducing the need for manual intervention.`)}
                    </StepContent>
                );

            case "DRAW_RECONCILIATION":
                return (
                    <StepContent showSummary={showContent}>
                        {/* {renderSummary(step.output?.status_summary)} */}
                        {renderKeyInsights(step.output?.key_insights)}
                        {/* {step.metadata?.financial_summary && (
                            <Text size="2" color="gray" as="p" mt="1">
                                Total Request: ${step.metadata.financial_summary.total_draw_request?.toFixed(2) ?? 'N/A'},
                                Documented: ${step.metadata.financial_summary.total_document_amount?.toFixed(2) ?? 'N/A'},
                                Over Budget: ${step.metadata.financial_summary.total_overbudget_amount?.toFixed(2) ?? 'N/A'}
                            </Text>
                        )} */}
                    </StepContent>
                );

            case "IDENTIFYING_DISCREPANCIES":
                return (
                    <StepContent showSummary={showContent}>
                        {renderSummary(step.output?.summary)}
                        {step.output?.top_issues && step.output.top_issues.length > 0 && (
                            <Box mt="2">
                                {step.output.top_issues.map((issueDesc, index) => (
                                    <Flex key={index} align="start" gap="2" mt="1">
                                        <Text size="1" color="gray" style={{ padding: "5px", backgroundColor: "var(--yellow-2)", borderColor: "var(--yellow-6)", borderWidth: "1px", borderStyle: "solid", borderRadius: "var(--radius-2)" }}>{issueDesc}</Text>
                                    </Flex>
                                ))}
                            </Box>
                        )}
                    </StepContent>
                );

            case "CHECKLIST_VALIDATION":
                if (!step.output?.rules || step.output.rules.length === 0) {
                    return <Text size="2" color="gray">No checklist rules evaluated.</Text>;
                }
                const failedRules = step.output.rules.filter(r => r.status === 'failed');
                const pendingRules = step.output.rules.filter(r => r.status === 'pending');
                const passedRules = step.output.rules.filter(r => r.status === 'passed');

                return (
                    <Flex direction="column" gap="3">
                        {/* Failed Rules */}
                        {failedRules.map((rule, index) => (
                            <RuleRenderer key={`failed-${index}`} rule={rule} />
                        ))}
                        {/* Pending Rules */}
                        {pendingRules.map((rule, index) => (
                            <RuleRenderer key={`pending-${index}`} rule={rule} />
                        ))}
                        {/* Passed Rules (Optional to show) */}
                        {passedRules.map((rule, index) => (
                            <RuleRenderer key={`passed-${index}`} rule={rule} />
                        ))}
                        {/* Separator if showing more sections */}
                        {/* {(failedRules.length > 0 || pendingRules.length > 0) && passedRules.length > 0 && <Separator size="4" my="2" />} */}

                        {/* Summary based on metadata */}
                        <Text size="1" color="gray" mt="2">
                            Rules Summary: {step.metadata.passed_rules ?? 0} Passed, {step.metadata.failed_rules ?? 0} Failed, {step.metadata.pending_rules ?? 0} Pending, {step.metadata.not_applicable_rules ?? 0} N/A (Total: {step.metadata.rules_count ?? 0})
                        </Text>
                    </Flex>
                );

            default:
                return (
                    <StepContent showSummary={showContent}>
                        {renderSummary(step.output?.summary)}
                        {step.error && <Text color="red" size="2">Error: {step.error}</Text>}
                    </StepContent>
                );
        }
    };

    return (
        <StepContainer mb="3">
            <Flex align="center" gap="2" mb="1">
                {stepIcon}
                <Text size="3" weight="medium">{stepTitle}</Text>
                {step.step_id.toUpperCase() !== "CHECKLIST_VALIDATION" && <Flex aria-label="Toggle Summary" onClick={toggleSummary} style={{
                    cursor: "pointer",
                    color: showSummary ? 'var(--blue-9)' : 'var(--gray-9)',
                    transition: 'color 0.2s ease'
                }}>
                    {showSummary ? <Icon.MagnifyingGlassMinus
                        size={16}
                    /> : <Icon.MagnifyingGlassPlus size={16} />}

                </Flex>}
                <HoverIcons>
                    <Icon.ThumbsUp size={14} style={{ cursor: "pointer" }} />
                    <Icon.ThumbsDown size={14} style={{ cursor: "pointer" }} />
                </HoverIcons>
            </Flex >
            <StepContent
                pl="6"
                showSummary={true}
            >
                {renderStepContent(showSummary)}
                {showSummary && step.executed_at && <Text size="1" color="gray" mt="1">Executed: {formatDate(step.executed_at)}</Text>}
            </StepContent>
        </StepContainer >
    );
};

const RuleRenderer: React.FC<{ rule: Rule }> = ({ rule }) => {
    const ruleIcon = getRuleStatusIcon(rule.status);

    return (
        <Box>
            <Flex align="center" gap="2">
                {ruleIcon}
                <Text size="2" weight={rule.status === 'failed' || rule.status === 'pending' ? 'bold' : 'regular'}>{rule.rule_name || camelize(rule.rule_id)}</Text>
            </Flex>
            {rule.suggested_action && (
                <Flex align="start" gap="2" mt="1">
                    <Text size="2" color="gray" style={{ padding: "5px", backgroundColor: rule.status === "failed" ? "var(--red-2)" : rule.status === "pending" ? "var(--yellow-2)" : "var(--green-2)", borderColor: rule.status === "failed" ? "var(--red-6)" : rule.status === "pending" ? "var(--yellow-6)" : "var(--green-6)", borderWidth: "1px", borderStyle: "solid", borderRadius: "var(--radius-2)" }}>{rule.suggested_action}</Text>
                </Flex>
            )}
            {/* {rule.evidence && rule.evidence.length > 0 && (
                <Box pl="4" mt="1">
                    <Text size="1" color="gray">Evidence:</Text>
                    <ul>
                        {rule.evidence.map((ev, i) => <li key={i}><Text size="1" color="gray">{ev.summary} ({ev.source_ref} - {ev.source_type})</Text></li>)}
                    </ul>
                </Box>
            )} */}
        </Box>
    );
};

const DrawReviewComponent: React.FC = () => {
    const [searchParams] = useSearchParams();
    const sectionRefs = useRef<Record<string, HTMLDivElement | null>>({});
    const [showModal, setShowModal] = useState(false);
    const [elapsedTime, setElapsedTime] = useState<string>('00:00:00');
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const pollingRef = useRef<NodeJS.Timeout | null>(null);

    const projectId = searchParams.get('projectId');
    const drawId = searchParams.get('drawId');
    const {
        draw,
        isLoadingDraw,
        drawError,
        refetchDraw: refetch,
    } = useDraw({
        projectId: projectId as string,
        drawId: drawId as string,
        skipDraws: true,
    });
    const [openSections, setOpenSections] = useState<string[]>(() => {
        if (draw) {
            return draw.sections.filter(s => s.expanded).map(s => s.section_id);
        }
        return [];
    });

    useEffect(() => {
        if (!draw || drawError) return;

        const drawStatus = draw.status as string;
        const isDrawFinished = ['COMPLETED', 'FAILED', 'CANCELLED', 'WARNING'].includes(drawStatus);

        if (!isDrawFinished) {
            pollingRef.current = setInterval(() => {
                refetch();
            }, 5000);
        }

        return () => {
            if (pollingRef.current) {
                clearInterval(pollingRef.current);
                pollingRef.current = null;
            }
        };
    }, [draw, drawError, refetch]);

    useEffect(() => {
        if (draw?.started_at && !timerRef.current) {
            const startTime = new Date(draw.started_at).getTime();

            const drawStatus = draw.status;
            const isCompleted = ['COMPLETED', 'FAILED', 'CANCELLED', 'WARNING'].includes(drawStatus);

            if (isCompleted && draw.completed_at) {
                const endTime = new Date(draw.completed_at).getTime();
                const totalSeconds = Math.floor((endTime - startTime) / 1000);
                setElapsedTime(formatElapsedTime(totalSeconds));
            } else {
                const updateTimer = () => {
                    const currentTime = Date.now();
                    const totalSeconds = Math.floor((currentTime - startTime) / 1000);
                    setElapsedTime(formatElapsedTime(totalSeconds));
                };

                updateTimer();
                timerRef.current = setInterval(updateTimer, 1000);
            }
        }

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
                timerRef.current = null;
            }
        };
    }, [draw]);

    React.useEffect(() => {
        if (draw && openSections.length === 0) {
            setOpenSections(draw.sections.filter(s => s.expanded).map(s => s.section_id));
        }
    }, [draw]);

    const handleMenuItemClick = (sectionId: string) => {
        const newOpenSections = openSections.includes(sectionId)
            ? openSections.filter(id => id !== sectionId)
            : [...openSections, sectionId];

        setOpenSections(newOpenSections);

        if (sectionRefs.current[sectionId]) {
            const headerHeight = document.querySelector('header')?.clientHeight || 60;

            const sectionElement = sectionRefs.current[sectionId];
            const sectionPosition = sectionElement?.getBoundingClientRect().top || 0;
            const offsetPosition = sectionPosition + window.scrollY - headerHeight - 20;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    const handleClose = () => {
        window.parent.postMessage('CLOSE_DRAW_REVIEW', '*');
    }

    if (isLoadingDraw) {
        return (
            <Flex justify="center" align="center" p="5" gap="2">
                <Spinner size="3" />
                <Text>Loading Draw Review...</Text>
            </Flex>
        );
    }

    if (drawError) {
        return (
            <Card>
                <Flex direction="column" p="3" gap="1">
                    <Flex align="center" gap="2">
                        <Icon.XCircle size={20} weight="fill" />
                        <Heading size="3" as="h3">Error Loading Draw Data</Heading>
                    </Flex>
                    <Text size="2" color="red">{(drawError as SerializedError).message}</Text>
                </Flex>
            </Card>
        );
    }

    if (!draw) {
        return (
            <Card>
                <Text>No draw data available.</Text>
            </Card>
        );
    }

    return (
        <Flex direction="column">
            <BuiltHeader justify="between" align="center" top="0" left="0" right="0">
                <Flex align="center" gap="2">
                    <Icon.Sparkle size={20} weight="fill" color="white" />
                    <Heading size="4">Draw agent reviewing - {draw.project_uid || draw.deal_id}</Heading>
                </Flex>
                <IconButton aria-label="Close" variant="ghost" style={{ cursor: "pointer" }} onClick={handleClose}>
                    <Icon.X weight="bold" color="white" size={20} />
                </IconButton>
            </BuiltHeader>

            <Grid columns="1fr 11fr" gap="4" >
                <MenuContainer direction="column" gap="2" style={{ backgroundColor: "#F2F3F8" }}>
                    {draw.sections.map((item) => (
                        <Flex
                            key={item.section_id}
                            align="center"
                            gap="2"
                            style={{
                                padding: "12px 16px",
                                cursor: "pointer",
                            }}
                            onClick={() => handleMenuItemClick(item.section_id)}
                        >
                            {getStatusIcon(item.status as Status)}
                            <Heading size="4" weight="regular" as='h3'>{getSectionTitle(item.section_id,)}</Heading>
                        </Flex>
                    ))}

                    <Flex justify="between" direction="column" align="center" mb="20px" position="absolute" bottom="0" left="0" right="0">
                        <Flex direction="column" align="center" gap="2" style={{ marginTop: "20px" }}>
                            <Flex align="center" gap="1">
                                {draw.status.toUpperCase() === "IN_PROGRESS" && <Icon.Pause size={14} />}
                                {(draw.status.toUpperCase() === "COMPLETED" || draw.status.toUpperCase() === "FAILED") && <Icon.ArrowCounterClockwise size={14} />}
                                <Text size="2" style={{
                                    fontFamily: "monospace",
                                    fontWeight: "bold",
                                    color: (draw?.status) === 'COMPLETED' ? 'var(--green-9)' :
                                        (draw?.status) === 'FAILED' ? 'var(--red-9)' : 'var(--blue-9)'
                                }}>
                                    {elapsedTime}
                                </Text>
                            </Flex>
                            {draw?.status && (
                                <Badge color={getStatusBadgeColor(draw.status as Status)} variant="soft" radius="full">
                                    {draw.status}
                                </Badge>
                            )}
                        </Flex>
                        <Separator size="4" my="4" />
                        <Text size="1" color="gray">Draw ID: {draw.draw_id}</Text>
                        <Text size="1" color="gray">Last Updated: {formatDate(draw.last_updated)}</Text>
                    </Flex>
                </MenuContainer>

                <Grid>
                    <Flex direction="column" gap="2" mt="20px">
                        {draw.sections.map((section) => (
                            <Flex
                                key={section.section_id}
                                direction="column"
                                gap="2"
                                ref={el => sectionRefs.current[section.section_id] = el}
                            >
                                <Flex align="center" gap="2" direction="column">
                                    <Flex
                                        align="center"
                                        justify="between"
                                        width="100%"
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            textAlign: 'left',
                                            cursor: 'pointer',
                                            borderRadius: 'var(--radius-2)'
                                        }}
                                        data-state={openSections.includes(section.section_id) ? 'open' : 'closed'}
                                    >
                                        <Flex align="center" gap="2">
                                            <Heading size="4" as="h3">{getSectionTitle(section.section_id, "icon")}</Heading>
                                            <Badge color={getStatusBadgeColor(section.status as Status)} variant="soft" radius="full">
                                                {section.status}
                                            </Badge>

                                            {/* TODO: Add back in when we have a way to collapse sections */}
                                            {/* <IconButton variant='ghost' color="gray" aria-hidden tabIndex={-1}>
                                                {openSections.includes(section.section_id) ? <Icon.CaretUp weight="bold" size={20} /> : <Icon.CaretDown weight="bold" size={20} />}
                                            </IconButton> */}
                                        </Flex>

                                    </Flex>
                                </Flex>

                                <Flex>
                                    <Box pt="2">
                                        {section.steps.map((step, index) => (
                                            <React.Fragment key={step.step_id}>
                                                <StepRenderer step={step as Step} allIssues={draw.issues} setShowModal={setShowModal} showModal={showModal} onSendNotification={() => setShowModal(false)} />
                                            </React.Fragment>
                                        ))}
                                    </Box>
                                </Flex>
                            </Flex>
                        ))}
                        <SendNotificationModal
                            title="Send Notification"
                            description="We found some issues with the uploaded documents. Please review the documents and upload the corrected version."
                            showModal={showModal}
                            handleModalClose={() => setShowModal(false)}
                        />
                    </Flex>
                </Grid>
            </Grid>
        </Flex>
    );
};

export default DrawReviewComponent;
