import { useState } from 'react';
import { personalizationApi } from '../api/personalizationApi';
import { PersonalizationSettings } from '../api/types';

export const usePersonalization = () => {
    const [ isUpdating, setIsUpdating ] = useState( false );

    const { data: personalizationSettings, isLoading, refetch } =
        personalizationApi.useGetPersonalizationSettingsQuery();

    const [ updatePersonalizationSettings ] =
        personalizationApi.useUpdatePersonalizationSettingsMutation();

    const updateSettings = async ( settings: Partial<PersonalizationSettings> ) => {
        setIsUpdating( true );
        try {
            await updatePersonalizationSettings( settings ).unwrap();
            await refetch();
            return true;
        } catch ( error ) {
            console.error( 'Failed to update personalization settings:', error );
            return false;
        } finally {
            setIsUpdating( false );
        }
    };

    return {
        personalizationSettings,
        isLoading,
        isUpdating,
        updateSettings,
    };
};
