import { agentApi } from './base';
import { mockDrawData } from '../data/mockData';

export type DrawSectionStatus = 'COMPLETED' | 'IN_PROGRESS' | 'PENDING' | 'ERROR';

export type Status = "COMPLETED" | "WARNING" | "FAILED" | "PENDING" | "IN_PROGRESS" | "NOT_APPLICABLE" | "VALID" | "INVALID" | "OPEN" | "RESOLVED";
export type Severity = "LOW" | "MEDIUM" | "HIGH";
export type IssueType = "AMOUNT_DISCREPANCY" | string;
export type SectionId = "DOCUMENT_VERIFICATION" | "DRAW_RECONCILIATION" | "DRAW_CHECKLIST" | string;
export type StepId = "document_validation" | "data_extraction" | "document_content_validation" | "draw_reconciliation" | "IDENTIFYING_DISCREPANCIES" | "checklist_validation" | string;
export type RuleStatus = "passed" | "failed" | "pending" | "not_applicable";

export interface Document {
    file_name: string;
    issue_id: string | null;
    status: Status;
}

export interface Issue {
    created_at: string;
    description: string;
    document_name: string | null;
    issue_id: string;
    issue_type: IssueType;
    required_action: string;
    resolution_notes: string | null;
    resolved_at: string | null;
    section_id: SectionId;
    severity: Severity;
    status: Status;
    step_id: StepId;
}

export interface RuleEvidence {
    source_ref: string;
    source_type: string;
    summary: string;
}

export interface Rule {
    rule_id: string;
    evaluated_at: string;
    evidence: RuleEvidence[];
    rule_name: string;
    status: RuleStatus;
    suggested_action: string | null;
}

export interface StepMetadata {
    documents_found?: number;
    documents_missing?: number;
    duplicates_found?: number;
    total_documents?: number;
    documents_processed?: number;
    failed_documents?: number;
    successful_documents?: number;
    category_amounts?: Record<string, number>;
    financial_summary?: {
        total_document_amount: number;
        total_draw_request: number;
        total_overbudget_amount: number;
        total_pending_amount: number;
    };
    issue_summary?: {
        overbudget_count: number;
        total_issues: number;
        unmatched_count: number;
    };
    line_items_matched?: number;
    line_items_total?: number;
    match_percentage?: number;
    discrepancies_found?: number;
    overbudget_amount?: number;
    overbudget_count?: number;
    unmatched_amount?: number;
    unmatched_count?: number;
    failed_rules?: number;
    not_applicable_rules?: number;
    passed_rules?: number;
    pending_rules?: number;
    rules_count?: number;
    [key: string]: any;
}

export interface StepOutput {
    key_insights?: string[];
    matched_percentage?: string;
    status_summary?: string;
    total_draw_amount?: string;
    has_more_issues?: boolean;
    summary?: string;
    top_issues?: string[];
    rules?: Rule[];
    [key: string]: any;
}

export interface Step {
    documents: Document[];
    error: string | null;
    executed_at: string | null;
    metadata: StepMetadata;
    output: StepOutput;
    status: Status;
    step_id: StepId;
}

export interface Section {
    expanded: boolean;
    section_id: SectionId;
    status: Status;
    steps: Step[];
}

export interface DrawData {
    completed_at: string | null;
    completed_steps: number;
    deal_id: string;
    draw_id: string;
    failed_steps: number;
    invalid_documents: number;
    issues: Issue[];
    last_updated: string;
    process_id: string;
    progress_percentage: number;
    project_uid: string;
    sections: Section[];
    started_at: string;
    status: Status;
    total_documents: number;
    total_steps: number;
    valid_documents: number;
    warning_steps: number;
    workflow_id: string;
}

export interface UseDrawResult {
    data: DrawData | null;
    isLoading: boolean;
    error: Error | null;
}

export interface DrawParams {
    draw_id: string;
    project_uid: string;
}

const drawApi = agentApi.injectEndpoints({
    endpoints: (builder) => ({
        getDraw: builder.query<DrawData, DrawParams>({
            query: (request) => ({
                url: '/draw-workflow/detail',
                method: 'GET',
                params: request,
            }),
            providesTags: (result, error, { draw_id }) => [{ type: 'Draw', id: draw_id }],
        }),
        getDraws: builder.query<DrawData[], string>({
            query: (projectId) => ({
                url: '/draw-workflow/list',
                method: 'GET',
                params: { project_uid: projectId },
            }),
            providesTags: [{ type: 'Draw' }],
        }),
        sendNotification: builder.mutation<void, { draw_id: string; message: string }>({
            query: ({ draw_id, message }) => ({
                url: '/draw-workflow/send-notification',
                method: 'POST',
                body: { draw_id, message },
            }),
        }),
    }),
    overrideExisting: false,
});

export const { useGetDrawQuery, useGetDrawsQuery, useSendNotificationMutation } = drawApi; 