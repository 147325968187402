import { useGetDrawQuery, useGetDrawsQuery } from '../api/drawApi';

interface UseDrawOptions {
    projectId: string;
    drawId?: string;
    skipDraw?: boolean;
    skipDraws?: boolean;
}

export function useDraw({ projectId, drawId, skipDraw = false, skipDraws = true }: UseDrawOptions) {
    const {
        data: draw,
        isLoading: isLoadingDraw,
        error: drawError,
        refetch: refetchDraw,
    } = useGetDrawQuery(
        { draw_id: drawId || '', project_uid: projectId },
        {
            skip: skipDraw || !drawId,
        }
    );

    const {
        data: draws,
        isLoading: isLoadingDraws,
        error: drawsError,
        refetch: refetchDraws,
    } = useGetDrawsQuery(projectId, {
        skip: skipDraws,
    });

    return {
        draw,
        draws,
        isLoadingDraw,
        isLoadingDraws,
        drawError,
        drawsError,
        refetchDraw,
        refetchDraws,
    };
}
