import styled from "styled-components";
import { Box, Flex } from "@mightybot/web-ui";

interface DocumentItemProps {
    status: "complete" | "failed" | "warning" | "pending" | string;
}

const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
        case 'complete':
        case 'passed':
            return 'var(--green-3)';
        case 'failed':
            return 'var(--red-3)';
        case 'warning':
            return 'var(--yellow-3)';
        case 'pending':
            return 'var(--gray-3)';
        default:
            return 'var(--gray-3)';
    }
};

export const BuiltContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: var(--gray-1);
`;

export const BuiltHeader = styled(Flex)`
    display: flex;
    align-items: center;
    padding: 12px 16px;
    background: var(--gray-1);
    border-bottom: 1px solid var(--gray-5);
    position: sticky;
    top: 0;
    height: 56px;
    z-index: 10;
    color: #FFFFFF;
    background-color: var(--mb-built-1);
`;

export const BuiltContent = styled.div`
    display: flex;
    height: calc(100vh - 56px); // Subtracting header height
    overflow: hidden;
`;

export const MenuContainer = styled(Flex)`
    width: 280px;
    background: var(--gray-1);
    border-right: 1px solid var(--gray-5);
    padding: 16px 8px;
    position: sticky;
    top: 56px; // Height of header
    height: calc(100vh - 56px);
`;

export const ContentContainer = styled.div`
    flex: 1;
    padding: 24px;
    overflow-y: auto;
    height: 100%;
`;

interface MenuItemProps {
    active?: boolean;
}

export const MenuItem = styled.button<MenuItemProps>`
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 8px 12px;
    border: none;
    border-radius: 6px;
    background: ${props => props.active ? 'var(--gray-4)' : 'transparent'};
    cursor: pointer;
    transition: background 0.2s ease;

    &:hover {
        background: var(--gray-3);
    }

    svg {
        color: ${props => props.active ? 'var(--gray-12)' : 'var(--gray-11)'};
    }

    span {
        color: ${props => props.active ? 'var(--gray-12)' : 'var(--gray-11)'};
        font-weight: ${props => props.active ? '500' : '400'};
    }
`;

export const MetadataContainer = styled.div<{ expanded: boolean }>`
    max-height: ${props => props.expanded ? '500px' : '0'};
    opacity: ${props => props.expanded ? '1' : '0'};
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    padding-left: 24px;
`;

export const IconWrapper = styled.div`
    cursor: pointer;
    transition: transform 0.3s ease;
    &:hover {
        transform: scale(1.1);
    }
`;

export const OutputContainer = styled.div`
    border-radius: 6px;
    background: var(--gray-3);
    padding: 8px 12px;
`;

export const DocumentsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 16px;
`;

export const DocumentItem = styled.div<DocumentItemProps>`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    border-radius: 6px;
    background: ${props => getStatusColor(props.status)};
    transition: background 0.2s ease;

    &:hover {
        filter: brightness(0.95);
    }
`;

export const HoverIcons = styled.div`
  display: none;
  gap: 8px;
`;

export const StepContainer = styled(Box)`
  padding-right: 40px;
  &:hover ${HoverIcons} {
    display: flex;
  }
`;

export const StepContent = styled(Box) <{ showSummary: boolean }>`
    opacity: ${props => props.showSummary ? 1 : 0};
    max-height: ${props => props.showSummary ? '100%' : '0'};
    overflow: hidden;
    transition: opacity 0.2s ease, max-height 0.3s ease;
    visibility: ${props => props.showSummary ? 'visible' : 'hidden'};
`;
