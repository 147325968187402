import { JSONContent } from '@tiptap/react';
import { UUID } from 'crypto';

import {
	AppName,
	Apps,
	CollectionType,
	ContentStatus,
	FavoriteType,
	FeedAction,
	FeedSource,
	MeetingCategoryType,
	MeetingDepartmentType,
	MeetingInternalExternal,
	MessageType,
	PeopleStatusOptions,
	PeopleTypeOptions,
	SnippetStatus,
} from '../commons/types';
import { Autopilot, TenantConfig } from '../features/types';

export type LoginRequest = {
	token: string;
	token_type: string;
	workspace_domain: string;
	tenant_id?: string;
};

export type LoginResponse = {
	data: {
		access_token: string;
		refresh_token: string;
		is_existing_user: boolean;
	};
	message: string;
};

export type EnterpriseResponse = {
	id: UUID;
	name: string;
	host_domain: string;
	logo: string;
	size: string;
};

export type AutopilotVariables = {
	[key: string]: {
		fields_required: string[];
		fields_optional: string[];
	};
};

export type AutopilotConfigResponse = {
	schedule_types: { id: string; label: string }[];
	execution_types: { id: string; label: string }[];
	apps: { id: string; label: string }[];
	events: { id: string; label: string; is_action_based: boolean }[];
	supported_prompt_variables: string[];
	agent_types: { id: string; label: string }[];
	event_fields_mapping: AutopilotVariables;
};

export type AutopilotTestRunResponse = {
	message: string;
};

export type EventTestRunRequest = {
	event_id: string;
};

export type AutopilotTestRunRequest = {
	autopilot_id: string;
	variable_values:
	| EventData
	| {
		[key: string]: string | string[] | null;
	};
};

export type EnterpriseAutopilots = {
	name: string;
	id: string;
	logo: string;
	users: User[];
	autopilots: {
		count: number;
	};
};

export type AutopilotsApiResponse = {
	autopilots: Autopilot[];
	total: number;
	page: number;
	per_page: number;
};

export type AutopilotsApiRequest = {
	enterpriseId?: UUID;
	page?: number;
	perPage?: number;
	search?: string;
	userIds?: UUID[];
	isActionAP?: boolean;
};

export type EnterpriseAutopilotsResponse = EnterpriseAutopilots[];

export type AutopilotAuthorResponse = {
	id: UUID;
	email: string;
	name: string;
};

export type RefreshTokenResponse = {
	data: {
		access_token: string;
	};
	message: string;
};

export type UserInfo = {
	name: string;
	title: string;
	enterprise_name?: string;
	app_connect_info: Record<AppName, { is_admin: boolean }>;
};

export type User = {
	email: string;
	id: UUID;
	name?: string;
	profile_pic?: string;
	type: string;
	is_admin: boolean;
	enterprise_id: UUID;
	onboarding_completed: boolean;
	role: TeamMemberRole;
};

export type MeResponse = {
	data: User;
	message: string;
};

export type MeetingPresencePreferences = {
	internal_meeting_bot_enabled: boolean;
	external_meeting_bot_enabled: boolean;
	excluded_participant_domains: string[];
	google_drive_folder_url: string;
};

export type DataConsent = {
	app_id: string;
	consent: boolean;
	consent_date: string;
	version: string;
};

export type NotificationPreferences = {
	web_notifications: boolean;
	push_notifications: boolean;
	email_notifications?: boolean;
	sms_notifications?: boolean;
	android_notifications?: boolean;
	ios_notifications?: boolean;
	frequency?: number;
	start_time?: number;
	end_time?: number;
	days?: string;
	post_meeting_summary?: boolean;
	pre_meeting_research?: boolean;
	salesforce_add_contacts?: boolean;
	summarize_emails?: boolean;
	slack_notifications?: boolean;
};

export type ConnectReminder = {
	dismissed: boolean;
	reminder_count: number;
	last_reminder_date: string | null;
	dont_show_again: boolean;
};

export type UserPreferencesResponse = {
	data_consent: DataConsent[];
	meeting_presence: MeetingPresencePreferences;
	notification: NotificationPreferences;
	connect_apps_reminder: ConnectReminder;
};

export type UserPreferencesRequest = {
	data_consent?: DataConsent[];
	meeting_presence?: Partial<MeetingPresencePreferences>;
	notification?: Partial<NotificationPreferences>;
	connect_apps_reminder?: Partial<ConnectReminder>;
};

export type MeetingContentRequest = {
	collection_id: string;
	collection_type?: CollectionType[];
	is_team_view?: boolean;
};

export type MeetingContentItem = {
	id: string;
	collection_id: string;
	collection_type: string[];
	source_agent_channel_id: UUID;
	title: string;
	content: string;
	cotent_metadata: {
		[key: string]: string;
	};
};

export type MeetingContentResponse = {
	items: MeetingContentItem[];
};

export interface MeetingRequest {
	meeting_id: string;
	is_team_view?: boolean;
}

export type MeetingVideoRequest = {
	meeting_id: string;
};

export type MeetingAttendee = {
	email: string;
	responseStatus: 'needsAction' | 'accepted' | 'declined' | 'tentative';
	self?: boolean;
	organizer?: boolean;
};

export type Meeting = {
	end_time: string;
	id: string;
	meeting_platform: 'google_meet' | 'zoom' | 'unknown';
	start_time: string;
	title: string;
	is_internal: boolean;
	meeting_category: MeetingCategoryType;
	meeting_type_department: MeetingDepartmentType;
	have_bot: boolean;
	meeting_url: string;
	organizer: string | null;
	bot_id?: string;
	bot_status?: string;
	bot_data?: {
		status_changes?: StatusChange[];
	};
	calendar_id?: string;
	created_ts?: string;
	event_id?: string;
	source_calendar_id?: string;
	updated_ts?: string;
	attendees: MeetingAttendee[] | string[];
	meeting_internal_external: MeetingInternalExternal;
	meeting_duration?: number;
};

export type MeetingResponse = Meeting | null;

export type MeetingsJoinResponse = {
	bot_id: string;
	meeting_url?: string;
	error?: string;
	message?: string;
	already_joined?: boolean;
	success?: boolean;
};

export type MeetingsJoinRequest = {
	meeting_url: URL;
};

export type ContextType = {
	type: 'contentIds' | 'plain-text' | 'metadata';
	value: string[] | string;
};

export interface AgentType {
	id: string;
	label: string;
}

export interface Agent {
	email: string;
	id: string;
	name: string;
	type: 'AGENT';
}

export interface CreateChannelResponse {
	data: {
		channel_id: string;
		agent_type: string;
		agent: Agent;
	};
	message: string;
}

export interface ChatMinimal {
	id: string;
	title: string;
	channel_id: string;
	agent_type: string;
	users: UUID[];
	created_at: number;
	updated_at: number;
	categories?: string[];
	feedback?: null | string;
	status?: 'waiting' | 'started' | undefined;
}

export type ChatsResponse = ChatMinimal[];

export type ChatResponse = ChatMinimal | null;

export type NewChat = { channelId: string; chatId: string; agentType: string };

export interface Skill {
	id: string;
	prompt: string;
	type: 'APP' | 'PLAYBOOK';
	apps: string[];
}

export type SkillsResponse = Skill[];

export type OnboardingResponse = {
	appConnected: boolean;
	chatStarted: boolean;
};

export interface Journal {
	id: string;
	title: string;
	users: User[];
	content?: JSONContent;
}

export type JournalsResponse = Journal[];

export type JournalResponse = Journal | null;

export interface JournalCategory {
	id: string;
	label: string;
}

export type JournalCategoriesResponse = JournalCategory[];

export interface Prompt {
	id: string;
	prompt: string;
}

export type PromptsResponse = string[];

export interface ChatMessageIncoming {
	message_id: string;
	parent_id: string | undefined;
	conversation_id: string;
	channel_id: string;
	user_id: string;
	user_type: 'USER' | 'AGENT';
	user_display_name: string;
	type: MessageType;
	timestamp: number;
	content: string;
	feedback?: null | string;
	data?: {
		label: string;
		details: string;
	};
}

export interface ChatJoinMessage {
	channel_id: string;
	user_id: string;
	user_type: 'USER';
}

export interface ChatMessageOutgoing {
	conversation_id: string;
	channel_id: string;
	user_id: string;
	user_type: 'USER';
	user_display_name: string;
	type: MessageType;
	timestamp: number;
	content: string;
	data?: { [key: string]: any };
}

export interface ChatMessageNode {
	message_id: string;
	message?: ChatMessageIncoming;
	children?: ChatMessageNode[];
}

export type IntegrationApp = {
	icon_url: string;
	id: string;
	integration_id: string;
	is_connected: boolean;
	name: string;
	redirect_url: string;
	scopes: string[];
};

export type EventData = {
	event_meeting_title: string;
	event_description: string;
	event_meeting_id: string;
	event_start_time: string;
	event_end_time: string;
	event_attendees: string[] | null;
	event_attendee_emails: { email: string; name: string | null }[];
	event_attendee_names: string[];
	event_meeting_description: string;
};

export type Event = {
	id: string;
	event_name: string;
	user_id: string;
	event_data: EventData;
	event_topic: string;
	created_ts: string;
};

export type UserRecentEvent = {
	events: Event[];
	total_count: number;
	page: number;
	page_size: number;
};

export type UserRecentEventsRequest = {
	event_name: string[];
	page: number;
	page_size: number;
};

export interface Log {
	id: string;
	autopilot_id: string;
	user_id: string;
	trace_id: string;
	trace_url: string[];
	execution_time: string;
	user_email: string;
	status: string;
	input: string[];
	output: string[];
	is_test: boolean;
	milestones: MilestoneGroup[];
	ap_config: Record<string, any> | null;
	user_name: string;
	autopilot_name: string;
	child_run_ids: string[];
	enterprise_name: string;
	data?: {
		id: string;
		title: string;
		status: string;
		user_message_short: string;
		links: string[];
		people: any[];
		apps: string[];
		tools: string[];
		category: string;
		company_name: string[];
		error: string | null;
		priority: string;
		created_ts: string;
	};
}

export interface SelectedLog {
	tracking_id: string;
	id: string;
	inputIndex: number;
	input: string;
	output: string;
	enterprise_name: string;
	is_test: boolean;
	trace_id: string;
	trace_url: string;
	autopilotName: string;
	agent_type?: string;
	child_run_ids?: string[];
	data?: {
		apps?: string[];
		category?: string;
		user_message_short?: string;
		status?: string;
		priority?: string;
		tools?: string[];
	};
}

export interface LogsResponse {
	logs: Log[];
	total: number;
	page: number;
	per_page: number;
}

export type UserFeedbackResponse = {
	id: UUID;
	user_id: UUID;
	entity_id: UUID;
	entity_type: 'MESSAGE' | 'MAIL';
	type: 'POSITIVE' | 'NEGATIVE';
	data: { [key: string]: any };
	created_ts: Date;
	updated_ts: Date;
};

export type UserFeedbackRequest = {
	entity_id: UUID;
	entity_type: 'MESSAGE' | 'MAIL';
	type: 'POSITIVE' | 'NEGATIVE';
	data: { [key: string]: any };
};

export type DisconnectAppResponse = {
	message: string;
	isSuccess: boolean;
	status: number;
};

export type AppType = {
	id: string;
	icon_url: string;
	integration_id: string;
	name: string;
	is_connected: boolean;
	scopes: string[];
	redirect_url: string;
	static_logo?: string;
	description?: string;
};

export type GoogleAppType = AppType & {
	integration_id: 'google';
	client_id: string;
	receive_code_url: string;
};

export type TenantResponse = {
	config: TenantConfig;
	created_ts: string;
	deleted_ts: string | null;
	entity_id: string;
	entity_type: number;
	id: string;
	is_primary: boolean;
	updated_ts: string;
	workspace_domain: string;
};

export type FetchTenantsParams = {
	default_tenant?: boolean;
	workspace_domain?: string;
	email?: string;
};

export interface FeedRequest {
	page: number;
	per_page: number;
	user_id?: string | null;
	category?: string[];
	person_email?: string | null;
	company_name?: string | null;
}

export interface FeedItem {
	links: string[];
	people?: User[] | null;
	apps: AppName[];
	category?: string | null;
	company_names: string[];
	structured_response_id?: UUID | null;
	user_id?: UUID | null;
	channel_id?: UUID | null;
	id?: UUID;
	title: string;
	description: string;
	signal_data?: { [key: string]: any };
	signal_type?: string;
	timestamp: string;
	expires_at?: string | null;
	relevance_score?: number;
	importance_score?: number;
	tldr?: string | null;
	emoji?: string | null;
	score?: number;
}

export interface FeedResponse {
	items: FeedItem[];
	meta: {
		total_count: number;
		total_pages: number;
		current_page: number;
		per_page: number;
	};
}

export interface EngagementActionRequest {
	signal_id: UUID | null;
	action: FeedAction;
	source: FeedSource;
}

export interface EngagementActionResponse {
	success: boolean;
}

export interface CompletionHistoryItem {
	id?: string;
	user_question: string;
	context?: any;
	response: string;
	created_at: string;
	author?: Partial<User>;
}

export interface CompletionCurrentItem extends Partial<CompletionHistoryItem> {
	prompt?: string | null;
}

export type Context = {
	type: string;
	value: string | string[] | { metastore_id: string };
	sub_type?: string;
};

export interface StreamPayload {
	collection_id: string;
	context: Context | Context[] | null;
	user_question: string;
	llm_model?: string;
	collection_type?: string;
	completion_id?: string;
	safe_context?: boolean;
}

export type MeetingsListResponse = Meeting[];

export type MeetingsListRequest = {
	direction?: 'past' | 'future';
	date?: string;
	page?: number;
	per_page?: number;
};

export interface MeetingsCreateRequest {
	title: string;
	description?: string;
	start_time: string;
	duration: number;
	// Add any other fields needed for creating a meeting
}

export interface MeetingsCreateResponse {
	id: string;
	title: string;
	description?: string;
	start_time: string;
	duration: number;
	// Add any other fields that the API returns after creating a meeting
}

export interface MeetingsBidirectionalListRequest {
	cursor?: string | null;
	per_page?: number;
	direction?: 'previous' | 'next';
	meeting_department?: MeetingDepartmentType[] | null;
	meeting_category?: MeetingCategoryType[] | null;
	meeting_internal_external?: MeetingInternalExternal | null;
	people?: string[] | null;
	date_range?: {
		from: string;
		to: string;
	};
	search_query?: string | null;
	team_members?: string[] | null;
	is_team_view?: boolean;
}

export interface MeetingsBidirectionalListResponse {
	meetings: Meeting[];
	has_previous?: boolean;
	has_next?: boolean;
	meeting_categories?: MeetingCategoryType[];
	meeting_departments?: MeetingDepartmentType[];
}

export interface NLSearchRequest {
	query: string;
	search_id?: UUID;
	filters?: { appFilter?: string[] };
	per_page?: number;
	page?: number;
}
export type NLSearchResult = {
	id: UUID;
	score?: number;
	final_score?: number | null;
	title: string | null;
	snippet?: string;
	app: string | null;
	time: string | null;
	owner_email?: string | string[] | null;
	link?: string | null;
	parent_id?: UUID | null;
};

export interface NLSearchResponse {
	results: NLSearchResult[];
	count: number;
	page: number;
	total_items: number;
	total_pages: number;
}
export type TimestampFilterValue = {
	type: 'preset' | 'custom_until_now' | 'custom_range' | 'any_time';
	startDate?: Date;
	endDate?: Date;
};

export type PeopleFilterValue = UUID[];

export type AppFilterValue = string[];

export type SearchFilters = {
	appFilter?: AppFilterValue | undefined;
	peopleFilter?: PeopleFilterValue | undefined;
	timestampFilter?: TimestampFilterValue | undefined;
};

export interface Search {
	id: UUID;
	user_id: UUID;
	query: string;
	date: string;
	results_count?: number;
	filters?: SearchFilters;
	source: string;
	success?: boolean;
	created_ts: string;
}

export interface CreateSearchRequest {
	query: string;
	filters?: SearchFilters;
	source: string;
}

export interface UpdateSearchRequest {
	query?: string;
	results_count?: number;
	filters?: SearchFilters;
	source?: string;
	success?: boolean;
}

export interface IndexingLog {
	name: string;
	is_indexed: boolean;
}

export interface IndexingStatus {
	message: string;
	status: 'completed' | 'in_progress';
	logs: IndexingLog[];
}
export interface StoreConversationRequest {
	message?: string;
	selected_items: NLSearchResult[];
	person_id?: UUID;
	company_id?: UUID;
	team_view?: boolean;
}

export interface StoreConversationResponse {
	conversation_id: UUID;
}

export interface UpdateConversationRequest {
	message?: string;
	selected_items?: NLSearchResult[];
	title?: string;
}

export interface UpdateConversationResponse {
	conversation: Conversation;
}

export interface Conversation {
	id: UUID;
	user_id: UUID;
	message?: string;
	title: string;
	selected_context_items: NLSearchResult[];
	created_ts: string;
}

export interface GetConversationsResponse {
	conversations: Conversation[];
}

export interface GetConversationByIdResponse {
	conversation: Conversation;
}
export interface Plan {
	id: string;
	name: string;
	description: string;
	prices: {
		monthly: number;
		yearly: number;
		currency: string;
	};
	max_seats: number;
	features: string[];
	is_enterprise: boolean;
}

export type SubscriptionStatus = 'ACTIVE' | 'EXPIRED' | 'CANCELLED';
export interface Subscription {
	name: string;
	subscription_id: string;
	customer_id: string;
	plan_id: string;
	status: SubscriptionStatus;
	trial_end_date?: string;
	trial_days_remaining?: number;
	current_seats: number;
	billing_cycle?: string;
	next_billing_date?: string;
	has_payment_method: boolean;
	amount: number;
	currency: string;
	is_trial: boolean;
	cancelled_at?: string;
	total_seats?: number;
}

export interface SubscriptionResponse {
	subscription: Subscription;
}

export interface SubscriptionStatusResponse {
	is_active: boolean;
}

export interface SubscribeRequest {
	plan_id: string;
	payment_method_id: string;
	billing_cycle: 'monthly' | 'yearly';
	seats: number;
}

export interface PaymentMethod {
	id: string;
	type: string;
	last4: string;
	exp_month: number;
	exp_year: number;
	is_default: boolean;
}
export interface Invoice {
	id: string;
	created_at: string;
	due_date: string;
	invoice_date: string;
	paid_date?: string;
	status: 'PAID' | 'PENDING' | 'UNPAID';
	number: string;
	currency: string;
	total: {
		amount: number;
		currency: string;
	};
	due: {
		amount: number;
		currency: string;
	};
	paid: {
		amount: number;
		currency: string;
	};
	line_items: Array<{
		description: string;
		amount: number;
		quantity: number;
	}>;
	hosted_link?: string;
	pdf_link?: string;
	subscription?: Record<string, any>;
}
export enum OnboardingStepStatus {
	NOT_STARTED = 'not_started',
	IN_PROGRESS = 'in_progress',
	COMPLETED = 'completed',
	FAILED = 'failed',
	SKIPPED = 'skipped',
}

export interface TourStatus {
	status: OnboardingStepStatus;
	remind_later: boolean;
}

export interface UpdateTourStatusRequest {
	status: OnboardingStepStatus;
	remind_later: boolean;
}

export type ExampleMeetingData = {
	fields: {
		meetings: Meeting[];
		meetingContent: MeetingContentItem[];
	};
};

export type ExampleFeedData = {
	fields: {
		feed: FeedItem[];
	};
};

export interface SearchCountsResponse {
	data: {
		counts: {
			[key in Apps]: number;
		};
		total: number;
	};
}

export type DocType = 'GOOGLE_DOC' | 'GOOGLE_SHEET';

export interface Template {
	id: UUID;
	name: string;
	version: string;
	type: DocType;
	scope: string;
}

export interface GenerateDocRequest {
	template_id: UUID;
	collection_id: string;
	document_name: string;
	selected_content_ids: UUID[];
	document_type: DocType;
	template_version: string;
	name_model: string | null;
}

export interface GenerateDocResponse {
	document_url: string;
	shared_with: string[];
	generated_at: string;
	used_model: string;
	template_used: { [key: string]: string };
	linter_passed: boolean;
	linter_messages: { [key: string]: string }[];
}

export interface DocumentOutput {
	collection_id: string;
	template_id: UUID;
	template_version: string;
	document_url: string;
	document_type: DocType;
	author_id: UUID;
	created_ts: string;
}
export type BOHMessageType = 'CHAIN_OF_THOUGHT' | 'REPLY' | 'ERROR';

export interface BOHTaskLog {
	id: string;
	message: string;
	display_message: string;
	message_type: BOHMessageType;
	created_ts: string;
	callback_data: Record<string, any> | null;
}

export interface BOHTaskLogsResponse {
	status: string;
	logs: BOHTaskLog[];
	progress: number;
}

export type TeamMemberRole = 'OWNER' | 'ADMIN' | 'MEMBER';

export interface TeamMember {
	id: string;
	email: string;
	name: string;
	role: TeamMemberRole;

	status: string;
	dateAdded: string;
	avatarColor?: string;
}

export interface TeamInvite {
	id: string;
	email: string;
	status: string;
	invitedBy: string;
	invitedAt: string;
	expiresAt: string;
}

export interface TeamResponse {
	members: TeamMember[];
	invites: TeamInvite[];
	isTeamAdmin: boolean;
	totalSeats: number;
}

export interface AddTeamMemberRequest {
	emails: string[];
}

export interface ResendInviteRequest {
	inviteId: string;
}

export interface CancelInviteRequest {
	inviteId: string;
}

type TranscriptWord = {
	confidence: number | null;
	end_timestamp: number;
	language: string | null;
	start_timestamp: number;
	text: string;
};

export type TranscriptSegment = {
	language: string | null;
	speaker: string;
	speaker_id: number;
	words: TranscriptWord[];
};

export interface CompletionLog {
	id: string;
	collection_id: string;
	collection_type: CollectionType;
	user_question: string;
	context: any;
	response: string;
	created_at: string;
	author: {
		id: string;
		name: string;
		email: string;
		profile_pic?: string;
	};
	selected_context_items?: any[];
}

export interface CreateCompletionRequest {
	collection_id: string;
	user_question: string;
	context?: any;
	llm_model?: string;
	collection_type: CollectionType;
	selected_context_items?: any[];
}

export interface Deal {
	id: string;
	name: string | null;
	stage: string | null;
	amount: number | null;
	currency: string | null;
	pipeline: string | null;
	close_date: string | null;
	is_closed: boolean;
	is_won: boolean;
	source_id: string;
	source: string;
	owner_id: string | null;
	company_id: string | null;
	enterprise_id: string | null;
	data: Record<string, any> | null;
	created_ts: string;
	updated_ts: string;
}

export interface Note {
	note_id: string;
	note_body: string;
	source?: string;
	created_ts?: string;
	created_by?: string;
	updated_ts?: string;
}

export interface Task {
	task_subject?: string;
	task_body?: string;
	task_status?: ContentStatus;
	task_priority?: string;
	task_type?: string;
	task_id?: string;
	created_ts?: string;
	task_due_date?: string;
}

export interface Person extends Omit<User, 'type'> {
	user_id?: string;
	status?: PeopleStatusOptions;
	last_seen?: string;
	source?: string;
	organization?: string;
	job_title?: string;
	phone?: string;
	about?: string;
	person_type?: PeopleTypeOptions;
	salesforce_id?: string;
	google_user_id?: string;
	hubspot_id?: string;
	slack_id?: string;
	linear_id?: string;
	tokenized_data?: Record<string, any>;
	created_ts?: string;
	updated_ts?: string;
	last_activity_date?: string;
	last_contacted_date?: string;
	last_interacted_ts?: string;
	industry?: string;
	contact_owner?: string;
	deals?: Deal[];
	lifecyclestage?: string;
	linkedin_url?: string;
}

export interface PersonDataResponse {
	[email: string]: Person;
}

export interface GetPersonDataRequest {
	emails: string[];
	user_id?: string;
}

export interface GetMeetingsMetastoreIdsRequest {
	ids: string[];
}

export interface GetMeetingsMetastoreIdsResponse {
	metastore_ids: { metastore_id: UUID; meeting_id: UUID }[];
}

export interface FavoritesResponse {
	favorites: string[];
}

export interface UpdateFavoriteRequest {
	entity_id: string;
	is_favorite: boolean;
	entity_type: FavoriteType;
	metadata?: Record<string, any>;
}

export interface BulkUpdateFavoriteRequest {
	entity_ids: string[];
	is_favorite: boolean;
	entity_type: FavoriteType;
	metadata?: Record<string, any>;
}

export interface GetUserFavoritesQuery {
	entity_type: FavoriteType;
}

export interface ShareMeetingRequest {
	user_emails?: string[];
	enterprise_id?: string;
	operation_on_users: 'add' | 'remove';
}

export interface ShareMeetingResponse {
	message: string;
}

export interface SharedUsersResponse {
	message: string;
	users: Partial<User>[];
}

export interface RelevantEnterprisePeopleResponse {
	message: string;
	users: Partial<User>[];
}

export type PaymentStatus =
	| 'SUCCESS'
	| 'FAILED'
	| 'PARTIAL_REFUNDED'
	| 'REFUNDED'
	| 'CANCELLED'
	| 'PROCESSING';
export interface PaymentTransaction {
	id: string;
	status: PaymentStatus;
	amount: number;
	currency: string;
	created_at: string;
	invoice_number: string;
	invoice_uuid: string;
	payment_method_type: string;
	card_last4?: string;
	card_brand?: string;
	card_expiry?: string;
}
export interface CreateSnippetRequest {
	video_url: string;
	start_time: number;
	end_time: number;
	clip_title?: string;
	clip_description?: string;
	source_collection_id: string;
	should_trim: boolean;
}

export interface Snippet {
	id: string;
	snippet_url: string;
	status: SnippetStatus;
	title: string;
	description?: string;
	created_by: string;
	container_url?: string;
	container_metadata: {
		start_time: number;
		end_time: number;
		duration: number;
	};
	structured_text: TranscriptSegment[];
	created_ts: string;
	video_url: string;
}

export interface ModifySnippetRequest {
	clip_title?: string;
	clip_description?: string;
}

export interface CreateSnippetResponse {
	id: string;
	snippet_url: string;
	status: string;
}

export interface DeleteSnippetResponse {
	status: string;
	message: string;
}

export interface PublicSnippetResponse {
	status: string;
	title: string;
	description: string;
	created_ts: string;
	container_metadata: {
		start_time: number;
		end_time: number;
		duration: number;
	};
	video_url: string;
	structured_text: TranscriptSegment[];
}

export interface FilterOptions {
	organization: string[];
	job_title: string[];
	status: string[];
	deals: string[];
}

export interface PersonDetailsParams {
	include_deals?: boolean;
	include_tasks?: boolean;
	include_notes?: boolean;
	include_activity?: boolean; // TODO: Remove this, not being used as activity is via search API
	include_latest_activity?: boolean;
}

export interface UserActivityLog {
	id: string;
	user_id: string;
	email: string;
	app_type: string;
	activity_event: string;
	created_ts: string;
	activity_ts: string;
	metastore_id: string;
}

export interface PersonDetailsResponse extends Person {
	hubspot_url?: string;
	salesforce_url?: string;
	deals?: Deal[];
	tasks?: Task[];
	notes?: Note[];
	last_contacted?: UserActivityLog;
	last_interaction?: UserActivityLog;
}

export interface PersonPaginationParams {
	per_page: number;
	page: number;
}

export interface PaginatedResponse<T> {
	data: T[];
	per_page: number;
	page: number;
	total: number;
	has_more: boolean;
}

export interface CreateTaskRequest {
	collection_id: string;
	collection_type: CollectionType[];
	title: string;
	content: string;
	status?: ContentStatus;
	content_metadata?: {
		due_date?: string;
		[key: string]: any;
	};
}

export interface UpdateTaskRequest {
	collection_id?: string;
	collection_type?: CollectionType[];
	title?: string;
	content?: string;
	status?: ContentStatus;
	content_metadata?: {
		due_date?: string;
		[key: string]: any;
	};
}

export interface TaskResponse {
	id: string;
	collection_id: string;
	title: string;
	content: string;
	content_type: string;
	content_metadata: Record<string, any>;
	created_ts: string;
	author_id: string;
	status: ContentStatus;
}

export interface CreateNoteRequest {
	collection_id: string;
	collection_type: CollectionType[];
	title: string;
	content: string;
	content_metadata?: Record<string, any>;
}

export interface UpdateNoteRequest {
	collection_id?: string;
	collection_type?: CollectionType[];
	title?: string;
	content?: string;
	content_metadata?: Record<string, any>;
}

export interface NoteResponse {
	id: string;
	collection_id: string;
	title: string;
	content: string;
	content_type: string;
	content_metadata: Record<string, any>;
	created_ts: string;
	author_id: string;
}
// TODO add a conversion function to convert the company data to the company data type
export interface CompanyData {
	// HUBSPOT
	name: string;
	domain: string;
	user_id: string;
	website: string;
	group_id: string;
	pipeline: string;
	object_id: string;
	createdate: string;
	open_deals: string;
	entity_type: string;
	lifecyclestage: string;
	associated_deals: {
		dealname?: string;
		object_id: string;
		createdate: string;
		lastmodifieddate: string;
	}[];
	lastmodifieddate: string;
	associated_contacts?: {
		email: string;
		object_id: string;
		createdate: string;
		lastmodifieddate: string;
	}[];
	target_account_probability: string;
	arr?: string;
	zip?: string;
	city?: string;
	type?: string;
	phone?: string;
	state?: string;
	address?: string;
	country?: string;
	product?: string;
	segment?: string;
	industry?: string;
	timezone?: string;
	closedate?: string;
	is_public?: string;
	processor?: string;
	description?: string;
	founded_year?: string;
	annualrevenue?: string;
	days_to_close?: string;
	total_revenue?: string;
	twitterhandle?: string;
	employee_range?: string;
	infrastructure?: string;
	debit_card_brand?: string;
	fintech_category?: string;
	total_deal_value?: string;
	web_technologies?: string;
	credit_card_brand?: string;
	numberofemployees?: string;
	total_money_raised?: string;
	total_funding_amount?: string;
	facebook_company_page?: string;
	linkedin_company_page?: string;
	debit_products_offered?: string;
	credit_products_offered?: string;
	digital_users_____of_installs?: string;
	// SALESFORCE
	headquarters: string;
	number_of_employees: string;
	linked_in__company__page: string;
}

export interface Company {
	id: string;
	name: string | null;
	domain: string | null;
	industry: string | null;
	description: string | null;
	website: string | null;

	// CRM IDs
	salesforce_id: string | null;
	hubspot_id: string | null;

	// Owner IDs for each CRM
	hubspot_owner_id: string | null;
	salesforce_owner_id: string | null;

	// Data from CRMs
	hubspot_data: Record<string, any> | null;
	salesforce_data: Record<string, any> | null;

	// Additional fields from the model
	type: string | null;
	source: string | null;
	linkedin_url: string | null;
	x_handle: string | null;
	employee_count: number | null;
	phone: string | null;
	address: string | null;
	city: string | null;
	state: string | null;
	country: string | null;
	zip_code: number | null;
	is_public: boolean;
	revenue: number | null;
	founded_year: number | null;
	logo_url: string | null;

	// Legacy fields
	source_id?: string;
	owner_id?: string | null;
	enterprise_id: string | null;
	created_ts: string;
	updated_ts: string;
}

export interface SearchQuery {
	common_facet_filters: Record<string, any>;
}

export interface StructuredSearchRequest {
	search_queries: SearchQuery[];
	page?: number;
	per_page?: number;
}

export interface PeopleListRequest {
	page: number;
	per_page: number;
	search?: string;
	companies?: string[];
	teams?: string[];
	deals?: string[];
	statuses?: PeopleStatusOptions[];
	type?: PeopleTypeOptions;
	internalOnly?: boolean;
	filterKey?: string;
}

export interface PeopleListResponse {
	people: (Person & { is_mb_user: boolean; profile_pic?: string })[];
	total: number;
	page: number;
	per_page: number;
	has_more: boolean;
}

export interface CompanyListRequest {
	page: number;
	per_page: number;
	enterprise_id: string | undefined;
	search?: string;
	type?: string[];
	industry?: string[];
	health?: string[];
	filterKey?: string;
}

export interface CompanyListResponse {
	companies: Company[];
	total: number;
	page: number;
	per_page: number;
	has_more: boolean;
}

export interface CompanyFilterOptions {
	industries: string[];
	healths: string[];
	company_owners: string[];
}
export interface MeetingRecordingPreferences {
	generate_doc_transcript: boolean;
	get_meeting_recording_in_drive: boolean;
}

export interface EnterpriseAppConfig {
	is_enabled: boolean;
	email_address: string;
}

export interface EnterprisePreferences {
	meeting_recording_preferences: MeetingRecordingPreferences;
	apps_config: Record<AppName, EnterpriseAppConfig>;
}

export interface SaveEnterprisePreferencesRequest {
	enterprise_id: string;
	preferences: Partial<EnterprisePreferences>;
}

export interface CompanyListParams {
	enterprise_id: string;
	page?: number;
	per_page?: number;
	search?: string;
}

export interface CreateCompanyRequest {
	name?: string;
	domain?: string;
	industry?: string;
	description?: string;
	website?: string;
	source_id: string;
	source: string;
	owner_id?: string;
	data?: Record<string, any>;
	enterprise_id?: string;
}

export interface UpdateCompanyRequest extends Partial<CreateCompanyRequest> { }

export interface DealListRequest {
	page: number;
	per_page: number;
	enterprise_id: string | undefined;
	search?: string;
	stage?: string[];
	pipeline?: string[];
	company?: string[];
	deal_owner?: string[];
	amount?: string[];
	close_date?: string[];
	is_closed?: boolean;
	is_won?: boolean;
	filterKey?: string;
}

export interface DealListResponse {
	deals: Deal[];
	total: number;
	page: number;
	per_page: number;
	has_more: boolean;
}

export interface DealFilterOptions {
	stages: string[];
	pipelines: string[];
	companies: string[];
	deal_owners: string[];
	amounts: string[];
	close_dates: string[];
}

export interface CreateDealRequest {
	name?: string;
	stage?: string;
	amount?: string;
	currency?: string;
	pipeline?: string;
	close_date?: string;
	is_closed?: boolean;
	is_won?: boolean;
	source_id: string;
	source: string;
	owner_id?: string;
	company_ids?: string[];
	enterprise_id?: string;
	data?: Record<string, any>;
}

export interface UpdateDealRequest extends Partial<CreateDealRequest> { }

export interface ActivitiesSearchRequest {
	entity_id: string;
	entity_type: CollectionType;
	page?: number;
	per_page?: number;
	team_view?: boolean;
	apps?: Apps[];
}

export interface ActivitiesSearchResponse {
	results: NLSearchResult[];
	total_items: number;
	total_pages: number;
	page: number;
	count: number;
}

export interface AutopilotLogsRequest {
	enterpriseId?: string[];
	autopilotId?: string[];
	userId?: string[];
	traceId?: string;
	dateFrom?: string;
	dateTo?: string;
	isTest?: boolean;
	page?: number;
	perPage?: number;
	showIndexLogs?: boolean;
}
export interface AutopilotMilestoneRequest {
	enterpriseId?: string[];
	autopilotId?: string[];
	isTest?: boolean;
	page?: number;
	perPage?: number;
	showIndexLogs?: boolean;
	userId?: string[];
	traceId?: string;
	dateFrom?: string;
	dateTo?: string;
}

export interface AutopilotLogsResponse {
	logs: Log[];
	total: number;
	page: number;
	per_page: number;
}
// Status change interface for bot status history
export interface StatusChange {
	code: string;
	sub_code?: string | null;
	message?: string | null;
	created_at: string;
}

export interface Milestone {
	milestone_name: string;
	result: boolean;
	reasoning: string;
	start_time?: string;
	end_time?: string;
}

export interface MilestoneGroup {
	prompt: string;
	milestones: Milestone[];
}

export interface LogMetadata {
	ap_name?: string;
	is_test?: string;
	agent_type?: string;
	parent_log_id?: string;
	[key: string]: any;
}

export interface LogComparisonRequest {
	log_id: string;
	agent_type: string;
}

export interface LogComparisonResponse {
	trace_id: string;
}

export interface PersonalizationSettings {
	base_preferences: string;
	email_instructions: string;
}
